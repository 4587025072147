import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC<{ showHeader?: boolean }> = ({ showHeader = true, children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
        />
        {showHeader && <Header title={data.site.siteMetadata.title} />}
        <script src="https://www.gstatic.com/firebasejs/8.2.7/firebase-app.js" />
        <script src="https://www.gstatic.com/firebasejs/8.2.7/firebase-analytics.js" />
        <script
          type="text/javascript"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyDUj83FxZLXtOWe1A-CUSgZ5KykY5ur8yA",
    authDomain: "cacc-30th-anniversary.firebaseapp.com",
    projectId: "cacc-30th-anniversary",
    storageBucket: "cacc-30th-anniversary.appspot.com",
    messagingSenderId: "312029820155",
    appId: "1:312029820155:web:abda3d877b2b2f1aebac5e",
    measurementId: "G-423062NTZV"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  `
          }}
        />

        <LayoutMain>{children}</LayoutMain>
      </LayoutRoot>
    )}
  />
)

export default IndexLayout
