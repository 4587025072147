import * as React from 'react'
import styled from '@emotion/styled'

import { breakpoints, dimensions } from '../styles/variables'
import { breakpointMq } from '../styles/mixins'

const StyledPage = styled.div({
  display: 'block',
  flex: 1,
  position: 'relative',
  padding: `0 ${dimensions.containerPadding}rem ${dimensions.containerPadding}rem ${dimensions.containerPadding}rem`,
  [`${breakpointMq(breakpoints.xxs, breakpoints.sm)}`]: {
    padding: `0 ${dimensions.containerPaddingMobile}rem ${dimensions.containerPaddingMobile}rem ${dimensions.containerPaddingMobile}rem`
  }
})

interface PageProps {
  className?: string
}

const Page: React.FC<PageProps> = ({ children, className }) => <StyledPage className={className}>{children}</StyledPage>

export default Page
