import * as React from 'react'
import styled from '@emotion/styled'

import { heights, colors, breakpoints, dimensions } from '../styles/variables'
import { HeaderLogo } from './HeaderLogo'
import { breakpointMq, getEmSize } from '../styles/mixins'

export const StyledHeader = styled.header({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: `${heights.header}rem`,
  padding: `0 ${dimensions.containerPadding}rem`,
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    height: `${heights.headerMobile}rem`,
    padding: `0 ${dimensions.containerPaddingMobile}rem`
  },
  color: `${colors.text}`,
  width: '100%',
  zIndex: 1
})

const StyledName = styled.div({
  fontSize: `${dimensions.headingSizes.h4}rem`,
  [breakpointMq(breakpoints.xxs, breakpoints.xs)]: {
    fontSize: `0.8rem`
  },
  [breakpointMq(breakpoints.xs, breakpoints.md)]: {
    fontSize: `0.9rem`
  }
})

const StyledHeaderLogo = styled(HeaderLogo)({
  flexShrink: 0,
  [breakpointMq(breakpoints.xxs, breakpoints.md)]: {
    width: `${getEmSize(56)}rem!important`,
    height: `${getEmSize(56)}rem!important`
  },
  width: `${getEmSize(90)}rem!important`,
  height: `${getEmSize(90)}rem!important`
})

const StyledAnchor = styled.a({
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none'
  }
})

interface HeaderProps {
  title: string
}

export const HeaderLogoGroup = () => (
  <StyledAnchor target="_blank" href="http://cacc.org.nz">
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        userSelect: 'none',
        color: colors.text
      }}
    >
      <StyledHeaderLogo />
      <div>
        <StyledName>Chinese Alliance Christian Church</StyledName>
        <StyledName>基督教宣道會中信堂</StyledName>
      </div>
    </div>
  </StyledAnchor>
)

const Header: React.FC<HeaderProps> = () => (
  <StyledHeader>
    <HeaderLogoGroup />
  </StyledHeader>
)

export default Header
