import * as React from 'react'
import GatsbyImage from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

export const HeaderLogo: React.FC<{ className?: string }> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "alliance-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return <GatsbyImage className={className} alt="Alliance church logo" fluid={data.file.childImageSharp.fluid} />
}
